@import '../../style/global';

.Grid {
  @include grid-container;
  margin: auto;
  max-width: $grid-width;
}

.OuterMargins {
  @include media-tablet {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }

  @include media-small-desktop {
    padding-left: $grid-column;
    padding-right: $grid-column;
  }

  @include media-regular-desktop {
    padding-left: $grid-column + $grid-gutter;
    padding-right: $grid-column + $grid-gutter;
  }
}
