@import '../../style/global';

.Container {
  display: flex;
  flex-direction: column;
  padding-bottom: $metrics-l;
  position: relative;
}

.Label {
  position: absolute;
  top: 0;
  transition: all .2s ease-out;
  @include Font-Head-N;
  color: $color-grey-lighten;

    &.isFocus {
      top: -$metrics-s;
      @include Font-Body;
    }
}

.Input {
    @include Font-Head-N;
    overflow: hidden;
    border: none;
    resize: none;
    box-sizing: content-box;
    margin-bottom: 0;

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-style: italic;
    }
  
    &::-ms-clear {
      display: none;
    }
  }

.Required {
  &::after {
    content: '*';
    color: $color-icons;
  }
}

.Dots {
  width: 100%;
  position: absolute;
  height: 2px;
  bottom: $metrics-l;
  left: 0;
  background-image: radial-gradient(rgb(0, 0, 0) 2px, rgb(239, 246, 250) 2px);
  background-size: 5px 10px;
}
