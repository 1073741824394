@font-face {
  font-family: "Baloo Tamma 2";
  src: url("../fonts/ttf/BalooTamma2-Regular.ttf") format("truetype");
  font-style: "normal";
  font-weight: 400;
}

@font-face {
  font-family: "Baloo Tamma 2";
  src: url("../fonts/ttf/BalooTamma2-SemiBold.ttf") format("truetype");
  font-style: "normal";
  font-weight: 600;
}