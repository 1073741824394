@import '../../style/global';

.Container {
  @include Font-Head-S;
}

.Title {
  @include Font-Head-L;
  line-height: 5rem;
  display: inline-block;
  font-weight: 700;
  width: 250px;

  @include media-mobile {
    @include Font-Head-XL;
    line-height: 6rem;
    width: 300px;
  }

  @include media-small-desktop {
    width: unset;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }
}

.Subtitle {
  @include Font-Head-S;
  color: $color-socials;
  font-weight: 700;
  display: block;
  padding-bottom: $metrics-m;
  text-align: right;
}

.ContactDetails {
  clear: both;
  @include Font-Head-S;
  padding: 50px 0;
  color: $color-primary;

   a {
    color: $color-primary;
  }
}

.SocialsContainer {
  display: flex;
  flex-direction: column;

  @include media-small-desktop {
    flex-direction: row;
  }
}

.Socials {
  display: flex;
  margin-top: $metrics-xs;
  margin-left: -($metrics-s);
  flex-wrap: wrap;

  @include media-small-desktop {
    margin: 0;
  }
}

.SocialLink {
  cursor: pointer;
  color: $color-primary;
  margin-left: $metrics-s;
  &:after {
    display: block;
    content: '';
    width: 100%;
    margin-top: -4px;
    border-bottom: 1px solid $color-primary;
  }
}
