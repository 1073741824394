@import '../../style/global';

.InnerLink {
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &:visited {
    color: inherit;
  }
}
