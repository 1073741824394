@import '../../style/global';

.AudioContainer {
  @include grid-collapse;
}

.Player {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $metrics-xs $metrics-s;
  padding-right: $metrics-xs;
  background-color: $color-primary;
  color: $color-white;
}

.PlayButton, .Button {
  border: none;
  width: $metrics-m;
  height: $metrics-s;
  background: transparent;
  cursor: pointer;
}

.Icon {
  width: 100%;
  height: 100%;
  fill: $color-white;
  filter: invert(100%);
}

.VolumeBar {
  margin: 0 $metrics-s;
  height: $metrics-xs;
  width: 5%;
  display: flex;
  align-items: center;
  cursor: pointer;
}