@import '../../style/global';

.ArticleBox {
  &:last-of-type {
    margin-bottom: 0;
  }
}

.Container {
  display: block;
  margin-bottom: $metrics-m;
  cursor: pointer;
  color: $color-primary;
  @include hover-opacity;
  text-decoration: none;
  background-color: $color-white;
  @include media-tablet {
    margin-bottom: 0;
  }
}

.ImageWrapper {
  overflow: hidden;
  position: relative;
  height: $metrics-xxl + $metrics-l;
}

.FullWidthImageWrapper {
  @include media-less-than-tablet {
    @include grid-collapse;
  }
}

.ImageContainer {
  height: 100%;
}

.Image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: top right;
  background-size: cover;
  transition: all .5s;

  &:hover {
    transform: scale(1.2);
  }
}

.Tags {
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 27px;
}

.Tag {
  padding-top: 7px;
}

.ArticleTag {
  position: absolute;
  right: 0;
  bottom: 0;
}

.Title {
  @include Font-Artile-Title;
  padding: $metrics-s + $metrics-xs 0 $metrics-xs 0;
}

.Subtitle {
  @include Font-Artile-Subtitle;
  margin-bottom: auto;
}

.LoadingAnimation {
	animation: moveUp 0.65s ease forwards;
}

@keyframes moveUp {
	0% {transform: translateY($metrics-xxl); }
	100% { transform: translateY(0); opacity: 1; }
}

.ArticleBox {
  display: flex;
  flex-direction: column;
}