$mobileBreakpoint: 500px;
$tabletBreakpoint: 768px;
$smallDesktopBreakpoint: 1100px;
$regularDesktopBreakpoint: 1366px;
$largeDesktopBreakpoint: 1920px;
$extraLargeDesktopBreakpoint: 2560px;

@mixin media-landscape {
  @media screen and (orientation:landscape) {
    @content;
  }
}

@mixin media-less-than-tablet {
  @media (max-width : $tabletBreakpoint - 1) {
    @content;
  }
}

// WIDTH MEDIA:
@mixin media-mobile {
  @media (min-width : $mobileBreakpoint) {
    @content;
  }
}

@mixin media-tablet {
  @media (min-width : $tabletBreakpoint) {
    @content;
  }
}

@mixin media-small-desktop {
  @media (min-width : $smallDesktopBreakpoint) {
    @content;
  }
}

@mixin media-regular-desktop {
  @media (min-width : $regularDesktopBreakpoint) {
    @content;
  }
}

@mixin media-large-desktop {
  @media (min-width : $largeDesktopBreakpoint) {
    @content;
  }
}

@mixin media-extra-large-desktop {
  @media (min-width : $extraLargeDesktopBreakpoint) {
    @content;
  }
}

// HEIGHT MEDIA:
$extraSmallHeight: 450px;
$smallHeight: 600px;

@mixin media-extra-small-height {
  @media (min-height: $extraSmallHeight) {
    @content;
  }
}

@mixin media-small-height {
  @media (min-height: $smallHeight) {
    @content;
  }
}
