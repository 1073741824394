@import '../../style/global';

.Container {
  padding-top: $metrics-l;
  overflow: hidden;

  @include media-small-desktop {
    overflow: visible;
  }

  *::selection {
    background-color: $color-secondary;
  }
}

.LogoWrapper {
  width: 100%;
  display: flex;
  padding-top: $metrics-s;
  justify-content: center;    
  @include media-tablet {
    padding-top: $metrics-m;
  }
}

.Logo {
  margin: 0;
  height: $metrics-s + $metrics-xs;
  margin-left: $metrics-s;

  @include media-tablet {
    margin: 0 auto;
    height: $metrics-m;
  }
}

.Grid {
  padding-bottom: $metrics-s;
  background-color: $color-white;
}

.FullWidthImage {
  margin: $metrics-blog-s 0;
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  object-fit: cover;
  z-index: 999;
  height: 720px;

  @include media-small-desktop {
    padding: $metrics-blog-m 0;
    height: 950px;
  }
}

.AudioContainer {
  @include grid-column(3, $mobile-grid);

  @include media-tablet {
    @include grid-column(12);
  }

  @include media-small-desktop {
    @include grid-column(6);
    @include grid-push(3);
  }
}

.ArticleBox {
  @include grid-column(3, $mobile-grid);

  @include media-tablet {
    @include grid-column(6);
  }

  @include media-small-desktop {
    @include grid-column(4);
  }
}

.SliderArticleBox {
  margin-left: $metrics-s;
  margin-bottom: $metrics-m;
}

.ReadMoreText {
  @include Font-Head-M;
  @include grid-column(3, $mobile-grid);
  padding-bottom: $metrics-s;

  @include media-tablet {
    padding-bottom: $metrics-s;
    @include grid-column(12);
  }
}

.VideoSection {
  margin: $metrics-blog-s 0;
  @include grid-column(3, $mobile-grid);
  float: none;
  height: 360px;
  z-index: 2;

  @include media-tablet {
    @include grid-column(12);
    margin-top: $metrics-blog-m;
    float: none;
    height: 480px;
  }

  @include media-small-desktop {
    @include grid-column(7);
    @include grid-push(3);
    margin-bottom: $metrics-blog-s;
    height: 480px;
  }
}

.Carousel {
  @include media-less-than-tablet {
    height: 100%;
    top: 0;
  }
}

.ShareButtons {
  display: flex;
  background-color: $color-grey;
  padding: $metrics-xs/2;
  border-radius: 5px;

  &:after {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 50%;
    transform: translate(-50%, 100%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $color-grey;
  }
}

.EmbeddedShareButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 $metrics-s $metrics-m $metrics-s;

  > div {
    display: flex;
    flex-direction: row;

    @include media-small-desktop {
      flex-direction: column;
      padding: 0 0 $metrics-m 0;
    }
  }

  @include media-small-desktop {
    flex-direction: column;
    position: fixed;
    top: $metrics-xxl + $metrics-xs * 4;
    left: 20%;
  }
}

.BottomGrid {
  position: relative;
  z-index: 99;
  background-color: $color-white;
}

.ArticleBox {
  margin-bottom: $metrics-s;
  @include media-tablet {
    margin-bottom: $metrics-m;
  }
}

.OtherArticlesContainer {
  overflow: hidden;
  width: 100%;

  @include media-tablet {
    width: 100%;
  }
}