@import '../../style/global';

.Container {
  position: relative;
  background-color: $color-primary;
  overflow: hidden;
}

.Video {
  @include object-fit-and-position(cover, 50% 50%);
  width: 100%;
  height: 100%;
  transform: scale(1.015);
}

.Iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
