@import '../../style/global';

.NewsletterTHankYouPage {
  padding-top: $metrics-l;
  overflow: hidden;

  @include media-small-desktop {
    overflow: visible;
  }
}

.LogoWrapper {
  width: 100%;
  display: flex;
  padding: $metrics-s 0 $metrics-m 0;

  @include media-small-desktop {
    padding: $metrics-m 0 $metrics-m * 2 0;

  }
}

.Logo {
  margin: 0;
  height: $metrics-s + $metrics-xs;
  margin-left: $metrics-s;

  @include media-tablet {
    margin: 0 auto;
    height: $metrics-m;
  }
}

.Grid {
  padding-bottom: $metrics-s;
  background-color: $color-white;
}

.Section {
  @include Flex-Center;
  flex-direction: column;
  padding: $metrics-s;
}

.MainHeading {
  padding: $metrics-s 0 $metrics-s 0;
  @include Font-Head-L;
  line-height: 44px;

  @include media-tablet {
    line-height: 126px;
    padding: 0;
  }

  @include media-tablet {
    @include Font-Head-O;
  }
}

.SmallDescription {
  padding: $metrics-xs 0 $metrics-s 0;
  font-size: 24px;
  line-height: 36px;
  position: relative;
  z-index: 1;
  text-align: center;
  
  @include media-tablet {
    padding: $metrics-s 0;
  }
  @include media-small-desktop {
    margin-right: 0;
  }
}

.Button {
  @include grid-column(3, $mobile-grid);
  @include Font-Body;
  width: 210px;
  height: 67px;
  line-height: $metrics-m;
  background-color: $color-green;
  color: $color-white;
  border: none;
  cursor: pointer;
  margin: 0;
  align-self: center;
  position: relative;
}