@import '../../style/global';

.Footer {
  background-color: $color-dark-background;
  color: $color-white;
  @include Font-Head-S;
  position: relative;
  
  @include media-small-desktop {
    padding-top: $metrics-m;
  }
}

.Wrapper {
  display: flex;
  flex-direction: column;
  padding: $metrics-s $metrics-s 0 $metrics-s;

  @include media-tablet {
    justify-content: space-between;
    padding: $metrics-s 0 0 0;
    margin-bottom: $metrics-s;
  }

  @include media-small-desktop {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
  }
}

.ImgBox {
  max-width: 354px;
  margin: 0 auto;
  .Img {
    width: 100%;
  }
  @include media-tablet {
    position: absolute;
    margin-left: 30px;
    transform: translateY(-29px);
  }
  @include media-small-desktop{
    transform: translateY(-60px);
  }
  @include media-regular-desktop {
    flex-basis: 354px;
    position: relative;
    margin: 0 0 0 30px;
  }
}

.Filters {
  color: $color-white;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $metrics-base;

  @include media-tablet {
    width: auto;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 0;
  }

  @include media-small-desktop {
    display: flex;
  }
}

.SectionsFiltersContainer {
  overflow: unset;
  padding-top: 0;
  padding-bottom: 0;
  position: inherit;
  width: auto;
}

.SocialMedia {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-top: $metrics-s;
  
  @include media-tablet {
    margin-top: 0;
    margin-left: 0;
    margin-right: $metrics-s;
  }
  
  @include media-small-desktop {
    justify-content: initial;
    align-items: baseline;
    margin-top: $metrics-s;
    margin-right: 0;
  }
}

.InfoSection {
  @include media-tablet {
    margin-left: 420px;
    margin-right: $metrics-s;
  }
  @include media-regular-desktop {
    flex-basis: 640px;
    flex-grow: 2;
    margin-right: 0;
    margin-left: $metrics-l;
  }
}

.Links {
  margin-top: $metrics-s;
  .Email {
    margin-bottom: $metrics-s;
    p>a{
      color: $color-white;
      text-decoration: underline;
    }
  }
  @include media-tablet {
    margin-left: $metrics-s;
    margin-right: $metrics-s;
  }
  @include media-small-desktop {
    flex-basis: 400px;
    margin-top: $metrics-l;
  }
  @include media-regular-desktop {
    margin-top: 0;
  }
}

.FooterInfo {
  padding: $metrics-s $metrics-s;

  @include media-tablet {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.Logo {
  height: $metrics-s;
  margin-bottom: $metrics-s + $metrics-xs;
  @include media-tablet {
    margin-bottom: 0;
  }
}
