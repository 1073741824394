
@import '../../../style/global';

.Header {
  @include grid-column(3, $mobile-grid);

  @include media-tablet {
    @include grid-column(12);
  }

  @include media-small-desktop {
    @include grid-column(7);
    @include grid-push(3);
  }
}

.Lead {
  @include Font-Head-M;
  padding: $metrics-xs 0;

  @include media-small-desktop {
    padding: $metrics-m 0;
  }
}

.Title {
  @include Font-Head-N;
  margin-top: 0;

  @include media-mobile {
    @include Font-Head-N;
  }

  @include media-small-desktop {
    @include Font-Head-O;
    margin-top: $metrics-m;
  }
}


.Date {
  @include Font-Body;
}

.Author {
  @include Font-Body;
  margin-left: $metrics-xs;
}
