@import '../../style/global';

$section-full-height: 560px;
$section-height-without-button: 470px;

// .Show {
//   height: $section-full-height;
//   transition: all 0.1s ease;

//   @include media-mobile {
//     height: $section-full-height;
//   }
// }

// .Hide {
//   height: $section-height-without-button;
//   transition: all 0.3s ease;

//   @include media-mobile {
//     height: $section-height-without-button;
//   }
// }

.Container {
  height: $section-full-height;
  background-color: $color-dark-background;
  color: $color-white;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.HeadingText {
  @include Font-Head-O;
  font-size: $fs-head-l;
  line-height: $lh-head-n;

  @include media-small-desktop {
    @include Font-Head-O;
  }
}

.SubHeadingText {
  @include Font-Head-O-Bolded;
  font-size: $fs-head-l;
  line-height: $lh-head-n;
  color: $color-green;
  margin-bottom: $metrics-m;

  @include media-small-desktop {
    @include Font-Head-O;
  }
}

.HeadingText, .SubHeadingText {
  @include grid-column(12);

  @include media-small-desktop {
    @include grid-column(12);
  }
}

.Link {
  position: relative;
  bottom: $metrics-m + $metrics-s;
  float: right;
}

.Button {
  background-color: $color-white;
  width: $metrics-m + $metrics-s;
  height: $metrics-m + $metrics-s;
  border: none;
  cursor: pointer;
  
  svg g path {
    fill: $color-icons;
  }

  &:hover {
    svg g path {
      fill: $color-dark-background;
    }
  }
}

.Image {
  width: $metrics-s/2;
  height: $metrics-s/2;
}

.Wrapper {
  @include grid-column(12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $metrics-s * 2;

  @include media-small-desktop {
    margin-bottom: $metrics-s * 3;
  }
}

.Logo {
  min-height: $metrics-s + $metrics-xs;
  max-height: $metrics-m + $metrics-xs;
}

.SocialMedia {
  position: absolute;
  bottom: $metrics-s;
  left: $metrics-s;

  @include media-mobile {
    position: relative;
    left: auto;
    bottom: auto;
    display: block;
  }
}