@import '../../../style/global';

.VolumeBar {
  margin: 0 $metrics-s / 2;
  height: $metrics-xs;
  flex-basis: 60%;
  display: flex;
  align-items: center;
  cursor: pointer;

  @include media-tablet {
    flex-basis: 20%;
    margin: 0 $metrics-s;
  }
}
