@import '../../../style/global';


.Title {
  padding: $metrics-s 0;
}

.DescriptionWrapper {
  display: flex;
  flex-direction: column;
  @include media-regular-desktop {
    flex-direction: row;
  }
}

.Description {
  @include Font-Body;
  opacity: 0.75;
  padding: 0 0 $metrics-s 0;

  @include media-regular-desktop {
    padding: 0 $metrics-l 0 0;
    width: 50%;
  }
}