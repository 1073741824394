@import '../../style/global';

.BlogListing {
  background-color: $color-white;
  position: relative;
}

.Grid {
  background-color: $color-white;
  margin-bottom: $metrics-m * 2;
}

.ArticleBoxesContainer {
  width: 100%;
  background-color: $color-white;
  display: flex;
  flex-wrap: wrap;
  padding: 0 $metrics-s;

  @include media-tablet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: $metrics-s + $metrics-xs;
    row-gap: $metrics-s;
    grid-auto-flow: dense;
  }

  @include media-small-desktop {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: $metrics-s + $metrics-xs;
    grid-auto-flow: dense;
    row-gap: $metrics-m;
  }
}

.PrimaryArticleBox {
  grid-column: span 2;
}

.SubText {
  @include Font-Body;
  @include grid-column(3, $mobile-grid);
  padding-bottom: $metrics-m;

  @include media-tablet {
    @include grid-collapse;
    @include grid-column(10);
    @include grid-push(1);
  }
}

.ArticleBox {
  @include grid-column(3, $mobile-grid);

  @include media-tablet {
    @include grid-column(6);
  }

  @include media-small-desktop {
    @include grid-column(4);
  }
}


.LoadMoreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: $metrics-m * 2;

  &:hover {
    .LoadMoreButton {
      color: $color-green;
    }

    .ArrowWrapper {
      svg g path {
        fill: $color-green;
      }
    }
  }
}

.LoadMoreButton {
  display: block;
  height: $metrics-s;
  border: none;
  color: $color-primary;
  background-color: $color-white;
  @include Font-Head-S-Const;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
}

.ArrowWrapper {
  margin-left: $metrics-xs;
  svg g path {
    fill: $color-dark-background;
  }
}

.FiltersWrapper {
  position: relative;
  height: $metrics-xl + $metrics-s / 2;

  @include media-mobile {
    height: auto;
    position: initial;
  }
}