@import '~style/common';

.HorizontalLine {
  @include grid-column(12);
  border-top: 1px solid $color-white;
  border-bottom: none;
  border-left: none;
  border-right: none;
  opacity: 0.1;
}
