@import '../../style/global';


.Container {
  display: block;
  position: relative;
  padding-left: $metrics-m;
  margin-bottom: $metrics-xs;
  cursor: pointer;
  @include Font-Body;
}

.Label {
  padding: 24px 0;
}

.SubLabel {
  display: block;
  @include Font-XS-Text;
  font-size: 1.5rem;
}

.Checkbox {
  position: absolute;
  opacity: 0;
  top: 0;
  left:0;
  cursor: pointer;
}

.Checked {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;

  &:before {
    content: "";
    position: absolute;
    display: block;
    bottom: 0px;
    height: 25px;
    width: 25px;
    border: 1px solid $color-primary;
    opacity: 0.55;
  }

  &.RequiredChecked {
    &:after {
      content: "";
      position: absolute;
      display: block;
      left: 9px;
      top: 0px;
      width: 8px;
      height: 17px;
      transform: rotate(45deg);
      border-style: solid;
      border-color: $color-icons;
      border-image: initial;
      border-width: 0px 1px 1px 0px;
    }
  }
}

