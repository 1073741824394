@import '../../style/global';

.ButtonContainer {
  width: $metrics-m;
  height: $metrics-s;
  position: fixed;
  top: $metrics-s;
  right: $metrics-s;
  display: flex;
  align-items: center;

  @include media-tablet {
    top: $metrics-m;
    right: $metrics-l;
  }

  &:focus {
    outline: none;
  }
}

.CloseButton {
  cursor: pointer;
  &::before, &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: $metrics-s / 2;
    top: 50%;
    right: 0;
    background: $color-primary;
  }

  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }     
}

.Text {
  @include Font-Body;
  cursor: pointer;
}

.CloseTextShow {
  position: absolute;
  right: $metrics-s;
  top: 3px;
  transition: all .3s;
  opacity: 1;
}

.CloseTextHide {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 3px;
  transition: all .3s;
}