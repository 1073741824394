@import '../../style/global';

.ContactSection {
  padding: $metrics-l 0;
  background-color: $color-secondary;

  @include media-small-desktop {
    padding-top: $metrics-xl;
  }
}

.Title {
  @include Font-Head-N;
  @include grid-column(3, $mobile-grid);
  margin-bottom: $metrics-l;

  @include media-tablet {
    @include grid-column(12)
  }

  @include media-small-desktop {
    @include grid-column(3);
  }
}

.Image {
  width: 100%;
  margin-bottom: $metrics-l;
  @include media-tablet {
    @include grid-column(6)
  }

  @include media-small-desktop {
    @include grid-column(3);
  }
}

.InfoColumn {
  @include grid-column(3, $mobile-grid);
  margin-bottom: $metrics-s;

  @include media-tablet {
    @include grid-column(6)
  }

  @include media-small-desktop {
    @include grid-column(5);
    @include grid-push(1);
    margin-bottom: $metrics-l;
  }
}
