@import '../../style/global';

.Wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  // justify-content: flex-start;
  // justify-content: space-between;
  // flex-wrap: wrap;
}

.Title {
  @include Font-Body-Const;
  color: $color-white;
  margin-right: $metrics-xs * 2;
  margin-left: 0;
  margin-top: $metrics-s - $metrics-xs;
  margin-bottom: $metrics-s - $metrics-xs;

  @include media-small-desktop {
    margin-right: $metrics-xs * 2;
    margin-top: $metrics-base / 2;
    margin-bottom: 0;
  }
}

.SocialMedias {
  height: 34px;

  a {
    margin-right: 0;
    margin-left: $metrics-xs * 2;
    cursor: pointer;

    svg path {
      fill: $color-white;
    }

    &:hover {
      svg path {
        fill: $color-icons;
      }
    }
  }

  > a:first-of-type {
    margin-left: 0;
  }

  @include media-tablet {
    > a:first-of-type {
      margin-left: $metrics-xs * 2;
    }
  }

  @include media-mobile {
    display: block;
    position: relative;
    bottom: auto;
  }

  @include media-small-desktop {
    > a:first-of-type {
      margin-left: 0;
    }
  }
}