@import '../../style/global';

.MenuBurger {

  position: relative;
  display: block;
  float: left;
  width: 30px;
  height: 18px;
  z-index: 2;
  cursor: pointer;

  border-top: 2px solid;
  border-bottom: 2px solid;

  color: inherit;
  font-size: 0;

  transition: all 0.2s cubic-bezier(0.35, 0.85, 0.5, 0.95) !important;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;

    position: absolute;
    top: 50%;
    left: 50%;

    background: currentColor;

    transform: translate(-50%, -50%);
    transition: transform 0.2s cubic-bezier(0.35, 0.85, 0.5, 0.95) !important;
  }

  &.BurgerOpen {
    border-color: transparent;

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
