@import '../../style/global';

.Copyright {
  display: flex;
  flex-direction: column;
  color: $color-dark-grey;
  @include Font-Head-S;

  @include media-tablet {
    align-items: center;
    flex-direction: row;
  }

  & span:first-of-type {
    margin-right: $metrics-base / 2;
  }
}

.Bolded {
  font-weight: 600;
}