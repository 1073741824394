@import '../../../style/global';

.Container {
  padding-top: $metrics-m + $metrics-base;
  overflow: hidden;
  position: absolute;
  width: 100vw;

  @include media-mobile {
    padding-top: $metrics-m + $metrics-s;
    padding-bottom: $metrics-m;
    width: auto;
    position: inherit;
  }
}

.Sections {
  color: $color-primary;
  @include Font-Body;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include media-tablet {
    @include grid-column(12);
  }
}

.CarouselSections {
  margin-left: $metrics-s;
}

.Section {
  cursor: pointer;
  list-style: none;
  @include Font-Body-Const;
  margin-right: $metrics-s;
  margin-bottom: 0;

  @include media-tablet {
    margin-bottom: $metrics-s;
  }

  &:focus {
    outline: none;
  }
}

.Section:hover {
  color: $color-green;
}

.SectionText {
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.Active, .Active:hover {
  border-bottom: 1px solid $color-dark-background;
}

.Counter {
  color: $color-dark-background;
}

.FooterCounter {
  color: $color-white;
}