@import '../../../../style/global';

.ShareButton {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  color: $color-primary;
  padding: 6px;
  cursor: pointer;
  transition-duration: 0.2s;
  outline: none;
  font-weight: 500;
  background-color: $color-secondary;
  border: 0;
  @include media-small-desktop {
    padding: 0 6px;
  }

  &:hover {
    background-color: $color-green;

    svg {
      filter: invert(1);
      cursor: pointer;
    }
  }
}

.EmbeddedShareButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $metrics-m + $metrics-xs;
  height: $metrics-m + $metrics-xs;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin-left: $metrics-xs * 2;
  margin-bottom: 0;
  background-color: $color-secondary;

  @include media-small-desktop {
    margin-bottom: $metrics-xs * 2;
    margin-left: 0;
  }

  &:hover {
    background-color: $color-green;

    svg {
      filter: invert(1);
      cursor: pointer;
    }
  }
}

.ButtonText {
  @include Font-Body;
  color: $color-primary;
  &:hover {
    color: $color-socials;
  }
}

.Label {
  @include Font-Body-Const;
  color: $color-primary;
  margin-bottom: 0;
  text-align: center;

  @include media-small-desktop {
    margin-bottom: $metrics-xs * 2;
  }
}