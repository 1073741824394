@import '../../style/global';

.Contain {
  background-size: contain;
  background-position: center center;
}

.Cover {
  background-size: cover;
  background-position: center center;
}

.Slide {
  background-size: cover;
  background-position: 40% center;
}

.Auto {
  background-size: auto;
  background-position: center center;
}

.IEImage {
  opacity: 0;
}