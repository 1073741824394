@import '../../../style/global';

.ProgressBar {
  height: $metrics-xs;
  flex-basis: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  @include media-tablet {
    margin: 0 $metrics-s;
  }
}

.Time {
  margin: 0 $metrics-xs * 2;
  margin-right: $metrics-s;
}
