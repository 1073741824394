@import '../../style/global';

.MainContainer {
  width: 100%;
  background-color: $color-light-green;
  @include Font-Head-S;
  padding-top: $metrics-m;
  padding-bottom: $metrics-m;
  position: relative;

  @include media-small-desktop {
    padding-top: $metrics-m * 2;
    padding-bottom: $metrics-m * 2;
  }
}

.TextContainer {
  @include grid-column(3, $mobile-grid);
  @include media-tablet {
    @include grid-column(12);
  }

  @include media-small-desktop {
    @include grid-column(6);
  }
}

.MainHeading {
  @include Font-Head-L;
  line-height: 44px;

  @include media-tablet {
    line-height: 126px;
  }

  @include media-tablet {
    @include Font-Head-O;
  }
}

.SmallDescription {
  padding: $metrics-xs 0 $metrics-s 0;
  font-size: 24px;
  line-height: 36px;
  position: relative;
  z-index: 1;
  
  @include media-tablet {
    padding: $metrics-m 0 0 0;
  }
  @include media-small-desktop {
    margin-right: $metrics-xxl;
  }
}

.Form {
  @include grid-column(3, $mobile-grid);
  padding: 0;
  display: flex;
  flex-direction: column;

  @include media-tablet {
    @include grid-column(12);
    padding: $metrics-xl 0 0 0;
  }

  @include media-small-desktop {
    @include grid-column(6);
  }
}

.Button {
  @include grid-column(3, $mobile-grid);
  @include Font-Body;
  width: 100%;
  height: 67px;
  line-height: $metrics-m;
  background-color: $color-green;
  color: $color-white;
  border: none;
  cursor: pointer;
  margin: 0;
  align-self: center;
  position: relative;

  @include media-small-desktop {
    align-self: flex-end;
    width: 210px;
  }
}

.InputField {
  padding-bottom: $metrics-s;
}

.Dots {
  bottom: $metrics-s;
}

.CheckboxVisible {
  float: none;
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: 1;
  margin-bottom: $metrics-s;
  transform: translateY(0);
  pointer-events: auto;
  transition: all 0.5s ease;
  transition-delay: 0.3s;
  @include Font-XS-Text;
}

.CheckboxHidden {
  position: absolute;
  opacity: 0;
  transform: translateY(-25px);
  pointer-events: none;
}

.Input {
  position: relative;
  span, input {
    background: transparent;
    color: $color-primary;
  }
}

.Image {
  position: absolute;
  bottom: -$metrics-m;
  left: -$metrics-l;
}

.Popup {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(#000, 0.5);
    @include Font-Head-S;

  .InnerContainer {
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    padding: $metrics-s;
    background-color: $color-white;
    opacity: 0;
    animation: moveUp 0.65s ease forwards;

    @include media-tablet {
      max-width: 700px;
      padding: $metrics-m;
    }
  }

  .TextContainer, .Form {
    float: none;
    width: 100%;
    margin-left: 0;
  }

  .CloseButton {
    position: absolute;
    top: $metrics-s;
    right: $metrics-s;

    @include media-tablet {
      right: $metrics-m;
    }
  }

  .MainHeading {
    @include Font-Head-N;

    @include media-mobile {
      @include Font-Head-L;
    }
  }

  .SmallDescription {
    padding: $metrics-xs 0 $metrics-s;

    @include media-tablet {
      padding-bottom: $metrics-m;
    }
  }

  .Form {
    padding: 0;
  }

  .Input {
    span, input {
      font-size: $fs-head-s;
      line-height: 1;
    }
  }

  .Button {
    margin: 0;
  }

  .CheckboxVisible {
    margin-top: -60px;

    label {
      @include Font-XS-Text;
      padding-left: $metrics-xs * 4;
    }

    @include media-mobile {
      margin-top: 0;
    }
  }

  @keyframes moveUp {
    0% {transform: translate(-50%, 0); }
    100% { transform:  translate(-50%, -50%); opacity: 1; }
  }
}

.BlogItemContainer {
  padding: $metrics-s + $metrics-xs;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $color-light-green;
  @include Font-Head-S;

  .Grid {
    height: 100%;
  }

  .MainHeading {
    @include Font-Head-N;
    font-weight: 600;

    @include media-mobile {
      @include Font-Head-M;
      font-weight: 600;
    }
  }

  .InnerContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .TextContainer {
    width: 100%;
    margin-left: 0;
  }
  
  .SmallDescription {
    padding: $metrics-s 0 $metrics-m 0;
    margin-right: 0;
    position: relative;
    z-index: 1;
  }
  
  .Form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    padding: 0;
  }

  .Input {
    padding-bottom: $metrics-s;
    span, input {
      font-size: $fs-head-s;
      line-height: 1;
    }
  }

  .InputField {
    padding-bottom: 0;
  }

  .Dots {
    bottom: 0;
  }

  .Image {
    bottom: -$metrics-m - $metrics-s;
    max-width: 213px;
  }

  .Button {
    width: 100%;
    margin: 0;
    z-index: 1;
    margin-top: auto;
  }
}

.Iframe {
  border: none;
  width: 548px;
  height: 235px;
}

.BlogItemIframe {
  border: none;
  width: 100%;
  height: 235px;
  overflow: hidden;
}