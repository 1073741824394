@import '../../style/global';

.Tag {
	display: inline-block;
	@include Font-XS-Text;
	padding: $metrics-xs/ 2 $metrics-s;
	background-color: $color-secondary;
	color: $color-primary;
}

.Counter {
	color: $color-grey-lighten;
	padding-left: 2px;
}
  