@import 'common';

$min-font-size : 8.5px;
$max-font-size: 10px;
$min-vw: $mobileBreakpoint;
$max-vw: $largeDesktopBreakpoint;

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

html {
  overflow-x: hidden;
  font-size: $min-font-size;
  line-height: $min-font-size;
  color: $color-primary;

  @include media-mobile {
    font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
    line-height: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
  }

  @include media-small-desktop {
    font-size: $min-font-size;
    line-height: $min-font-size;
  }

  @include media-regular-desktop {
    font-size: $max-font-size;
    line-height: $max-font-size;
  }
}

:global {
  * {
    margin: 0;
    padding: 0;
    min-height: 0;
    min-width: 0;
    box-sizing: border-box;
  }

  a:link {
    text-decoration: none;
  }
  .slick-list {
    overflow: visible;
  }
  
  .slick-track {
    display: flex;
  
    .slick-slide {
      display: flex;
      height: auto;
      align-items: center;
    }
  }
}
