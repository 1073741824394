@import '../../style/global';

.Heading {
  height: $metrics-l;
  background-color: $color-primary;
  color: $color-white;
  @include Font-Body;
  position: relative;

  @include media-small-desktop {
    height: $metrics-xxl;
  }
}

.MenuBurgerContainer{
  position: absolute;
  left: $metrics-s;
  top: $metrics-s;
  z-index: 999;

  @include media-small-desktop {
    position: fixed;
    left: $metrics-m;
    top: $metrics-m;
  }
}

.MenuBurgerWhiteColor {
  color: $color-white;
}

.MenuBurgerDarkColor {
  color: $color-primary
}

.CollapedMenu {
  width: 100%;
  height: auto;
  background-color: $color-white;
  position: fixed;
  left: 0;

  @include media-small-desktop {
    height: 100vh;
  }
}

.NavShow {
  position: fixed;
  top: 0;
  height: 100vh;
  transition: all .5s;
  z-index: 1;
  overflow-y: auto;

  @include media-small-desktop {
    position: fixed;
    top: 0;
    height: 100%;
    overflow: hidden;
  }
}

.NavHide {
  position: absolute;
  opacity: 0;
  top: -100%;
  transition: all .5s;

  @include media-small-desktop {
    position: fixed;
    top: -100%;
  }
}

.OpenedMenuContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;

  @include media-small-desktop {
    flex-direction: row;
    height: 100%;
  }
}

.AssetContainer {
  overflow: hidden;
  width: 100%;
  height: 100%;
  order: 2;
  display: flex;
  align-items: stretch;

  @include media-small-desktop {
    order: 1;
    flex: 1 0 35%;
  }
}

.Image {
  width: 100%;
  object-fit: cover;
  display: block;
  margin-bottom: -$metrics-s;

  @include media-small-desktop {
    &.ImageShow {
      transform: scale(1, 1);
      transition: transform 0.5s;
    }

    &.ImageHide {
      transform: scale(1.5, 1.5);
      transition: transform 0.5s;
    }
  }
}

.InfoColumnContainer {
  background-color: $color-secondary;
  order: 3;
  position: relative;

  @include media-small-desktop {
    flex: 1 0 30%;
    order: 2;

  }
}

.InfoColumn {
  padding: $metrics-s;
  padding-bottom: $metrics-blog-xl;

  @include media-small-desktop {
    max-width: 400px;
    &.InfoShow {
      opacity: 1;
      position: absolute;
      top: 10%;
      left: 0;
      right: 0;
      margin: auto;
      transition: all 0.4s;
    }

    &.InfoHide {
      opacity: 0;
      position: absolute;
      top: -100%;
      transform: all 0.4s;
    }
  }

  .ContactDetails {
    @include Font-Head-S;
  }
}

.LinksContainer {
  background-color: $color-primary;
  padding: $metrics-xl $metrics-s $metrics-s;
  order: 1;

  @include media-small-desktop {
    padding: $metrics-m;
    padding-top: 320px;
    flex: 1 0 40%;
    order: 3;
  }
}

.NavbarSocialsContainer  {
  display: flex;
  flex-direction: column;
  padding-top: $metrics-m;

  div:first-child  {
    &:after {
      display: block;
      content: '';
      width: 100%;
      margin-top: 4px;
      border-bottom: 1px solid $color-grey-lighten;
      opacity: 0.1;
    }
  }

  div:nth-child(2) {
    margin-top: $metrics-xs;
    margin-left: -($metrics-s);
  }
}

.Links {
  @include Font-Head-S;
  font-weight: 300;
  color: $color-white;
  flex-direction: column;
  @include grid-column(12);

  @include media-small-desktop {
    &.LinksShow {
      opacity: 1;
      position: absolute;
      top: 40%;
      transition: all 0.5s;
    }

    &.LinksHide {
      opacity: 0;
      position: absolute;
      top: 0;
      transform: all 0.5s;
    }
  }
}


.Text {
  position: absolute;
  right: $metrics-s;
  top: $metrics-s;

  @include media-tablet {
    top: $metrics-m;
  }

  @include media-small-desktop {
    top: $metrics-xl;
    @include grid-column(2);
    @include grid-push(1);
  }
}
